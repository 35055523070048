<template>
    <VModal name="confirmQuit" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_warning.svg" alt="">
        <h3>
          Bist du sicher?
        </h3>
        <p>
          Möchtest du das laufende Workout wirklich abbrechen?
        </p>
        <v-btn type="button" class="button button--primary button--small" @click="reallyQuit">Ja, beenden</v-btn>
        <v-btn type="button" class="button button--secondary button--small" @click="dismissDialog">Nein, Training fortsetzen</v-btn>
      </div>
    </VModal>
</template>

<style lang="scss">
</style>
<script>
export default {
    name: 'ConfirmQuitModal',
    methods: {
        dismissDialog() {
            this.$modal.hide('confirmQuit');
            this.$emit("cancelQuit");
        },
        reallyQuit() {
            this.$modal.hide('confirmQuit');
            this.$emit("reallyQuit");
        },
    }
}
</script>