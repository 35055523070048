<template>
    <div class="playlist-player-modal">
        <!-- HEADER -->
        <div class="deckslide__header">
            <div class="deckslide__header__left">

                <!-- Information -->
                <img src="@/assets/ic_nav_info_white.svg" alt="" @click="openInfoModal">

                <!-- Moderation On -->
                <img v-show="beepEnabled" @click="showSoundOptions" src="@/assets/ic_nav_music-on.svg">
                
                <!-- Music On -->
                <img v-show="!beepEnabled" @click="showSoundOptions" src="@/assets/ic_nav_music-on-active.svg">
                
                <!-- Contextmenu: Moderation vs. Music -->
                <div class="deckslide__header__contextmenu">
                    <ul v-if="showSoundMenu">
                        <li>
                            <input type="radio" value="true" v-model="beepEnabled">
                            <label for="beep" @click="beepOn">Hinweistöne</label>
                        </li>
                        <li>
                            <input type="radio" value="false" v-model="beepEnabled">
                            <label for="music" @click="beepOff">Eigene Musik / Podcast</label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="deckslide__header__center">
                <span v-if="!pause">{{ exerciseTitle }}</span>
                <span v-else>Workout</span>
                <span class="progress-header"> ({{playbackPosition + 1}}/{{videoFileList.length}})</span>
            </div>
            <div class="deckslide__header__right">
                <img @click="confirmQuit"  src="@/assets/ic_nav_close_white.svg" alt="">
            </div>
        </div>

        <div class="playlist-player-modal__progress">
            <span>Übung</span> {{playbackPosition + 1}}/{{videoFileList.length}}
        </div>

        <div v-show="pause" class="playlist-player-modal__countdown">
            <div class="playlist-player-modal__countdown__time">
                {{ pauseLength }}
            </div>
        </div>

        <div v-show="pause" class="playlist__countdown__teaser">
            Gleich geht's weiter mit
            <div class="playlist__countdown__teaser__title">
                {{ exerciseTitle }}
            </div>
            <img v-show="exercisePreviewImage != null" :src="'https://app.sportlerplus.com/scaled/800/' + exercisePreviewImage" alt="">
        </div>

        <div v-show="!pause" @click="toggleControlsLandscape" class="playlist-player-modal__countdown playlist-player-modal__countdown__time is-running">
            {{ videoCountdown }}
        </div>

        <div v-show="orientation == 'landscape'" class="control-toggle" @click="toggleControlsLandscape"></div>

        <!-- VIDEO -->
        <div v-show="!pause" class="video-player">
            <div v-show="warmupPhase" class="video-player__badge">WarmUp</div>
            <video ref="videoPlayer" class="video-js" playsinline preload="auto"></video>
        </div>

        <div v-show="!pause && !hideControlsLandscape" class="video-controls">
            <div class="video-controls__prev">
                <img @click="skipToPrevExercise" src="@/assets/video-control--prev.svg" alt="Previous Video">
            </div>
            <div class="video-controls__pause">
                <img v-show="videoPlaying" @click="togglePlay" src="@/assets/video-control--pause.svg" alt="Pause Video">
                <img v-show="!videoPlaying" @click="togglePlay" src="@/assets/video-control--play.svg" alt="Pause Video">
            </div>
            <div class="video-controls__next">
                <img @click="skipToNextExercise" src="@/assets/video-control--next.svg" alt="Next Video">
            </div>
        </div>

        <!-- BG IMAGE -->
        <div v-if="(homePageInfo && homePageInfo.image_url)"  class="deckslide__background">
            <img :src="'https://app.sportlerplus.com/scaled/800/'+ homePageInfo.image_url" />
        </div>

        <ExerciseInfoModal :exercise="currentExercise" @closeInfoModal=closeInfoModal></ExerciseInfoModal>
        <ConfirmQuitModal @reallyQuit="cancelPlaylist" @cancelQuit="cancelQuit"></ConfirmQuitModal>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css'

import ConfirmQuitModal from '@/components/ConfirmQuitModal.vue';
import ExerciseInfoModal from "@/components/ExerciseInfoModal.vue";

export default {
    name: 'PlaylistVideoPlayer',
    data() { return {
        videoOptions: null,
        workoutSource: null,
        videoFileList: [],
        playbackPosition: 0,
        player: null,
        repeatCount: 1,
        pause: false,
        pauseLength: 0,
        videoCountdown: 0,
        videoPlaying: false,
        infoModalOpen: false,
        didStopVideoForInfoDisplay: false,
        currentExercise: null,
        hideControlsLandscape: false,
        hideControlsTimeout: null,
        orientation: null,
        soundSignalPlayed: true,
        pauseInterval: null,
        restartPlayer: false,
        fitnessPlan: null,
        homePageInfo: null,
        beepEnabled: true,
        showSoundMenu: false,
    }},
    computed: {
        exerciseTitle() {
            if (this.videoFileList && this.videoFileList.length > this.playbackPosition) {
                return this.videoFileList[this.playbackPosition].title;
            }
            else {
                return "Übung";
            }
        },
        exercisePreviewImage() {
            if (this.videoFileList && this.videoFileList.length > this.playbackPosition) {
                return this.videoFileList[this.playbackPosition].preview_image_url;
            }
            else {
                return null;
            }    
        },
        warmupPhase() {
            if (this.workoutSource && this.workoutSource.warmupEnd) {
                return (this.workoutSource.warmupEnd >= (this.playbackPosition+1));
            }
            return false;
        }
    },
    components: { ExerciseInfoModal, ConfirmQuitModal },
    methods: {
        showSoundOptions() {
            this.showSoundMenu = !this.showSoundMenu;
        },
        beepOff() {
            if (this.player) {
                this.beepEnabled = false;
                this.showSoundOptions()
                this.$store.commit('setSoundEnabled', this.beepEnabled);
            }
        },
        beepOn() {
            if (this.player) {
                this.beepEnabled = true;
                this.showSoundOptions()
                this.$store.commit('setSoundEnabled', this.beepEnabled);
            }
        },
        openInfoModal() {
            this.infoModalOpen = true;
            if (!this.pause && !this.player.paused()) {
                this.player.pause();
                this.videoPlaying = false;
                this.didStopVideoForInfoDisplay = true;
            }
            else {
                this.didStopVideoForInfoDisplay = false;
            }
            this.$modal.show('exerciseInfo');
        },
        closeInfoModal() {
            this.infoModalOpen = false;
            if (this.didStopVideoForInfoDisplay) {
                this.player.play();
                this.videoPlaying = true;
            }
        },
        toggleControlsLandscape() {
            if (this.orientation == "landscape") {
                this.hideControlsLandscape = !this.hideControlsLandscape;
                if (this.hideControlsTimeout) {
                    clearTimeout(this.hideControlsTimeout);
                }
                if (!this.hideControlsLandscape) {
                    this.hideControlsTimeout = setTimeout(this.toggleControlsLandscape, 3000);
                }
            }
        },
        restartHideControlsTimeout() {
            if (this.hideControlsTimeout) {
                clearTimeout(this.hideControlsTimeout);
            }
            this.hideControlsTimeout = setTimeout(this.toggleControlsLandscape, 3000);
        },
        handleOrientationChange() {
            let orientation = screen.orientation.type.match(/\w+/)[0];
            this.orientation = orientation;
            if (orientation == "landscape") {
                this.hideControlsLandscape = true;
            }
            else {
                this.hideControlsLandscape = false;
            }
        },
        handleOrientationChangeFallback() {
            let orientation = window.orientation;
            if (orientation === undefined) { return; }
            if (orientation == 0) {
                this.orientation = "portrait";
                this.hideControlsLandscape = false;
            }
            else {
                this.orientation = "landscape";
                this.hideControlsLandscape = true;
            }
        },
        confirmQuit() {
            let pauseActive = this.player.paused();
            if (!pauseActive) {
                this.player.pause();
                this.restartPlayer = true;
            }
            else {
                this.restartPlayer = false;
            }
            this.$modal.show('confirmQuit')
        },
        cancelQuit() {
            if (this.restartPlayer) {
                this.restartPlayer = false;
                this.player.play();
            }
        },
        init() {
            try {
                screen.orientation.onchange = this.handleOrientationChange;
                setTimeout(this.handleOrientationChange, 2000);
            }
            catch(error) {
                // not available on iphone
                //alert(error);
                window.addEventListener("orientationchange", this.handleOrientationChangeFallback, false);
                setTimeout(this.handleOrientationChangeFallback, 2000);
            }
            this.workoutSource = this.$store.getters.getWorkoutSource;
            this.videoFileList = this.workoutSource.generatedPlaylist;

            this.$store.dispatch('fetchHomePage').then( () => {
                this.homePageInfo = this.$store.getters.getHomePageInfo;
            });
            
            this.beepEnabled = this.$store.getters.getSoundEnabled;
            this.currentExercise = this.videoFileList[this.playbackPosition];
            this.exerciseLength = this.currentExercise.exerciseLength || this.workoutSource.exerciseLength;
            this.videoCountdown = this.exerciseLength;

            this.videoOptions = {
                preload: "auto",
                autoplay: true,
                muted: true,
                controls: false,
                fluid: true,
                nativeControlsForTouch: false,
                sources: [this.getVideoFile(this.currentExercise)]
            };

            this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
            this.videoPlaying = true;
            this.soundSignalPlayed = false;
            /*this.player.on('ready', () => {
                this.player.play();
                this.videoPlaying = true;
                this.soundSignalPlayed = false;
            });*/
            this.player.on('timeupdate', () => {
                this.videoCountdown = this.exerciseLength - (this.repeatCount - 1) * 15 - parseInt(this.player.currentTime())
                if ((this.videoCountdown <= 3) && !this.soundSignalPlayed) {
                    this.soundSignalPlayed = true;
                    this.playSoundSample();
                }
            });
            this.player.on('error', () => {
                console.log(this.player.error());
                this.skipToNextExercise();
            });
            this.player.on('ended', () => {
                this.player.autoplay(false);
                if (this.exerciseLength / 15 > this.repeatCount) {
                    console.log("repeat loop");
                    this.repeatCount++;
                    this.player.play();
                    this.videoPlaying = true;
                }
                else {
                    this.repeatCount = 1;
                    this.playbackPosition++;
                    this.addEnergyConsumption(this.exerciseLength, this.currentExercise.mez);
                    if (this.playbackPosition < this.videoFileList.length) {
                        console.log("next item");
                        let newPlaybackItem = this.videoFileList[this.playbackPosition];
                        let itemPauseLength = this.currentExercise.pauseLength || this.workoutSource.pauseLength;
                        this.pauseLength = itemPauseLength;
                        this.currentExercise = newPlaybackItem;
                        this.exerciseLength = newPlaybackItem.exerciseLength || this.workoutSource.exerciseLength;
                        this.videoCountdown = this.exerciseLength;
                        this.player.src(this.getVideoFile(newPlaybackItem));
                        this.pauseExercise(() => {
                            this.addEnergyConsumption(itemPauseLength, 1);
                            this.player.play();
                            this.videoPlaying = true;
                            this.soundSignalPlayed = false;
                        });

                    }
                    else {
                        this.playlistEnd();
                    }
                }
            });

        },
        /* duration in seconds, factor = mez value from exercise or 1 for pause */
        addEnergyConsumption(duration, factor) {
            if (duration == 0) { return; }
            //console.log("adding consumption: " + duration + "s, " + factor);
            let arr = this.workoutSource.completedExercises || [];
            arr.push([duration, factor]);
        },
        skipToNextExercise() {
            if (this.playbackPosition < this.videoFileList.length - 1) {
                this.playlistLoadItem(this.playbackPosition+1);
            }
            else {
                this.playlistEnd();
            }
            this.restartHideControlsTimeout();
        },
        skipToPrevExercise() {
            if (this.playbackPosition > 0) {
                this.playlistLoadItem(this.playbackPosition-1);
            }
            this.restartHideControlsTimeout();
        },
        /* skip to next, previous item */
        playlistLoadItem(newPosition) {
            if (newPosition >= 0 && newPosition < this.videoFileList.length) {
                this.addEnergyConsumption(this.exerciseLength - this.videoCountdown, this.currentExercise.mez);
                console.log("load item " + newPosition);
                this.player.pause();
                this.playbackPosition = newPosition;
                this.repeatCount = 1;
                let newPlaybackItem = this.videoFileList[this.playbackPosition];
                this.currentExercise = newPlaybackItem;
                this.exerciseLength = newPlaybackItem.exerciseLength || this.workoutSource.exerciseLength;
                this.videoCountdown = this.exerciseLength;
                this.player.src(this.getVideoFile(newPlaybackItem));
                this.player.play();
                this.soundSignalPlayed = false;
            }
        },
        playlistEnd() {
            console.log("playlist end");
            this.$router.push("/playlist/success");
        },
        cancelPlaylist() {
            if (this.workoutSource.fitnessPlanEvent) {
                this.markFitnessPlanEventAsCancelled(this.workoutSource.fitnessPlanEvent);
                this.$router.push("/fitnessplan");
            }
            else {
                this.$router.push("/trainieren");
            }
        },
        async markFitnessPlanEventAsCancelled(eventInfo) {
            await this.$store.dispatch('fetchFitnessPlan').then( (res) => {
				if (res.length != 0) {
					this.fitnessPlan = res[0];
                    let existingStartedActivity = null;
                    if (this.fitnessPlan.startedActivities) {
                        existingStartedActivity = this.fitnessPlan.startedActivities.find(el => el.guid == eventInfo.guid && el.activityDate == eventInfo.activityDate && el.activityTime == eventInfo.activityTime);
                    }
                    else {
                        this.fitnessPlan.startedActivities = [];
                    }

                    if (existingStartedActivity) {
                        existingStartedActivity.activityState = "cancelled";
                        this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
                    }
                }
            });
        },
        pauseExercise(callback) {
            if (this.pauseLength == 0) {
                callback();
                return;
            }
            console.log("pause");
            this.pause = true;
            this.pauseInterval = setInterval(() => {
                if (!this.infoModalOpen) {
                    if (this.pauseLength-- == 1) {
                        clearInterval(this.pauseInterval);
                        this.pause = false;
                        console.log("pause end");
                        callback();
                    }
                    else if (this.pauseLength == 3) {
                        this.playSoundSample();
                    }
                }
            }, 1000);
        },
        getVideoFile(exerciseItem) {
            return {
                src: "https://media.sportlerplus.com/loops/" + exerciseItem.video_file,
                type: "video/mp4",
            }
        },
        togglePlay() {
            if (this.player.paused()) {
                this.player.play();
                this.videoPlaying = true;
            }
            else {
                this.player.pause();
                this.videoPlaying = false;
            }
        },
        playSoundSample() {
            if (!this.beepEnabled) { return; }
            let sampleURL = "/sounds/Beep_1.mp3";
            let audioObj = new Audio(sampleURL);
            audioObj.play();
        }
    },
    mounted() {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.idleTimerMessageHandler) {
            window.webkit.messageHandlers.idleTimerMessageHandler.postMessage({disabled: true})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.acquireWakeLock();
        }
        else {
            this.vueInsomnia().on();
        }
        this.init();
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
        if (this.pauseInterval) {
            clearInterval(this.pauseInterval);
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.idleTimerMessageHandler) {
            window.webkit.messageHandlers.idleTimerMessageHandler.postMessage({disabled: false})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.releaseWakeLock();
        }
        else {
            this.vueInsomnia().off();
        }
    },
}
</script>

<style lang="scss">
    .playlist-player-modal {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $colorBlack;
        z-index: 1;
        flex: 1;
        padding: 0 20px 8px;

        @media screen and (orientation: portrait) {
            .progress-header {
                display: none;
            }
        }

        @media screen and (orientation: landscape) {
            justify-content: flex-start;

            .playlist__header {
                position: relative;
                z-index: 3;

                .progress-header {
                    display: inline-block;
                    margin-left: 6px;
                }
            }
        }

        &__countdown {
            text-align: center;
            margin: auto 0;

            &__time {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                width: 120px;
                height: 120px;
                padding-bottom: 8px;
                margin: auto 0 16px;
                font-size: 38px;
                letter-spacing: -1.33px;
                font-weight: 900;
                color: $colorWhite;
                background: url('../assets/countdown.png');
                background-size: cover;
                animation: pulse infinite 1s;

                @media screen and (orientation: landscape) {
                    width: 100px;
                    height: 100px;
                    padding-bottom: 4px;
                    font-size: 32px;
                    margin: 0;
                }
            }

            h1 {
                color: $colorWhite;
                margin-bottom: 32px;

                @media screen and (orientation: landscape) {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 8px;
                }
            }

            @media screen and (orientation: landscape) {
                &.is-running {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 60px;
                    height: 60px;
                    font-size: 22px;
                    font-weight: 700;
                    padding-bottom: 2px;
                    margin: inherit;
                    z-index: 2;
                }
            }
        }

        &__sound {
            display: none;

            @media screen and (orientation: landscape) {
                z-index: 3;
                display: flex;
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
        }

        &__progress {
            font-size: 14px;
            font-weight: 600;
            color: $colorWhite;
            margin: -8px 0 -16px;
            text-align: center;
            opacity: .48;

            @media screen and (orientation: landscape) {
                display: none;
            }
        }

        .video-player {
            position: relative;
            width: calc(100% + 40px);
            margin: 0 -20px auto;
            background: #000;

            @media screen and (orientation: landscape) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                overflow: hidden;
            }

            video {
                @media screen and (orientation: landscape) {
                    left: 50% !important;
                    height: 100vh !important;
                    transform: translateX(-50%) !important;
                }
            }

            &__badge {
                position: absolute;
                z-index: 100;
                top: 16px;
                right: 16px;
                background: rgba($colorPrimary, .16);
                padding: 6px 10px;
                color: $colorPrimary;
                border-radius: 3px;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;

                @media screen and (orientation: landscape) {
                    top: initial;
                    bottom: 24px;
                    right: 24px;
                }
            }
        }
    }
</style>